import React from 'react';
import Resume from '../components/Resume';

function ResumePage() {
  return (
    <div>
        <Resume />
    </div>
  );
}

export default ResumePage;